import React, { Component } from 'react';
import { Spin, Table, Switch, message, Row, Col, Select, Menu, Dropdown, Button, Input, Divider } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import RPC from 'api';
import { Icon, LabelSelect, LabelText } from 'libs';
import { LangConsumer } from 'utils/LangProvider';
import { bindLifecycle } from 'utils/bindLifecycle';
// import { withRouter } from 'react-router-dom';
import Ability from '../../../../ability';
import ObjectMonitorPlugin from './ObjectMonitorPlugin';
import { update } from '../../../../store/views/AIConfig/IVSObjectMonitor/action';
import WebApp from '../../../../webapp';
import { transToChannelName } from '../../../../common';
import '../index.less';
import ConfigForm from './ConfigForm';
import { EventHandler, ScheduleDialog } from 'publicComponent';
import plugin from 'plugin';
const { Option } = Select;
const { Column } = Table;
import { lengthB, subStrB } from 'utils/checkbit';
const translations = {
    'ObjectPlacement': 'ivs.ObjectPlacement', //物品放置
    'ObjectRemoval': 'ivs.ObjectRemoval', //物品拿取
};
const supportEvents = ['ObjectPlacementDetection', 'ObjectRemovalDetection'];
const withoutPresetId = 1000; //全局方案预置点
@connect(
    state => ({ publicState: state.publicState, webCaps: state.webCaps, IVSObjectMonitor: state.IVSObjectMonitor }),
    { update },
    null,
    { forwardRef: true }
)

class RuleConfig extends Component {
    constructor (props) {
        super(props);
        this.ComponentName = 'RuleConfig';
        this.ObjectMonitorPlugin = React.createRef();
        // this.configForm = React.createRef();
        this.pictureOverlay = React.createRef();
        this.ScheduleDialog = React.createRef();
        this.state = {
            loading: false,//是否加载完配置
            presets: [],//预置点数据
            PresetID: null,
            PresetName: '',
            isShowPreset: false,
            scene: {},
            cfgName: ['VideoAnalyseRule', 'VideoAnalyseGlobal'],//配置列表
            cfgData: [],//取到的所有配置
            ClassType: 'ObjectMonitor',//智能大类
            objectRule: [],//物体检测相关配置
            supportedRules: [],//设备支持的视频结构化规则
            // availableRules: [],//可以使用的规则类型，每种规则类型只能用一次
            ruleTemp: null,//规则模板
            editingField: '',//表格中当前编辑的列，空表示非编辑状态
            seletedRow: 0,//表格当前选中行的索引
            flowStaCaps: {},//流量统计能力
            timeSection: [], // 保存时间计划表信息
            linkEvent: {}, // 支持的智能事件
            currentEditRuleName: '', // 当前正在修改的规则名称,用来规则名称的判空处理
            trackEnable: false, //是否关联
            supportedSceneWithoutPreset: false, //是否支持全局方案
            channelOptions: [], //通道选项
            currentChannel: 0, //当前通道
            isShowZoom: false,
            isShowFocus: false,
            isShowIris: false,
            supportSnapshot: false //是否支持重瞳
        };
    }
    componentDidActivate () {
        // console.log('componentDidActivate');
        const { tabKey } = this.props;
        let { objectRule, seletedRow, PresetID, currentChannel, isShowPreset } = this.state;
        // 重新进入页面后，重新绘制
        if (tabKey === '1') {
            if (objectRule[seletedRow]) {
                this.ObjectMonitorPlugin.current.setBtnStatus(0); // 重新设置按钮样式
                this.ObjectMonitorPlugin.current.setDrawingExcludeStatus(false);
                this.ObjectMonitorPlugin.current.drawRule(objectRule[seletedRow].Enable);
            } else {
                this.ObjectMonitorPlugin.current.drawRule(false);
            }
        }
    }
    // 返回智能方案页面
    preStep = () => {
        this.props.preStep('IVSObjectMonitor');
    }
    /**
     * 改变通道
     * @param {}
     */
    changeChannel = async (value) => {
        await this.confirm();
        this.setState({ currentChannel: value, loading: false }, async () => {
            this.showPTZmenu();
            this.ObjectMonitorPlugin.current.open(1, value, 0);
            await this.getIVSCaps();
            this.getConfig(false, true);
        });
    }
    showPTZmenu () {
        let caps = WebApp.ChannelPTZCaps && WebApp.ChannelPTZCaps[this.state.currentChannel];
        if (caps && Object.keys(caps).length > 0) {
            this.setState({
                isShowZoom: caps.Zoom !== false,
                isShowFocus: caps.Focus !== false,
                isShowIris: caps.Iris !== false
            });
        } else {
            this.setState({
                isShowZoom: false,
                isShowFocus: false,
                isShowIris: false
            });
        }
    }
    //通道列表展开、收起
    onTogChnLst = visible => {
        let { channelOptions } = this.state;
        if (channelOptions.length <= 1) return;
        if (!this.ObjectMonitorPlugin.current) return;
        if (visible) {
            this.ObjectMonitorPlugin.current.hide();
        } else {
            this.ObjectMonitorPlugin.current.cover();
        }
    }
    componentDidMount () {
        let { pageChannels, $t, currentPlanID } = this.props;
        /* 设置页面通道选项 */
        let channelOptionsTmp = [];
        for (let i = 0; i < pageChannels.length; i++) {
            channelOptionsTmp[i] = { value: pageChannels[i], text: transToChannelName($t, pageChannels[i]) };
        }
        this.setState({ channelOptions: channelOptionsTmp, currentChannel: channelOptionsTmp[0] ? channelOptionsTmp[0].value : 0 }, async () => {
            this.showPTZmenu();
            await this.getIVSCaps();
            plugin.addEvent('pluginInstall', () => {
                this.getConfig();
            });//登入后控件安装完成
            this.getConfig();
        });
    }
    getIVSCaps = async () => {
        let { ClassType, supportedRules, ruleTemp, cfgName, flowStaCaps, linkEvent, supportedSceneWithoutPreset, supportSnapshot, currentChannel } = this.state;
        let [{ FlowStat }] = await Promise.all([Ability.get('PD')]);
        let scene = WebApp.ChannelCapsInfo[currentChannel].SupportedScenes[ClassType];
        // 非机动车检测 人员检测 配置绊线
        // 结构化机非人规则是否支持独立设置检测区域和排除区域
        //cfgName = ['VideoAnalyseRule', 'VideoAnalyseGlobal', 'VideoAnalyseModule'];
        supportedRules = Object.keys(scene.SupportedRules);
        try {
            //获取智能模板
            ruleTemp = await RPC.VideoInAnalyse.getTemplateRule({ class: ClassType, channel: currentChannel }).then(res => res[ClassType]);
        } catch (err) {
            // console.log(err);
        }
        if (WebApp.EventCaps) {
            await RPC.EventManager.getEventLink({ code: supportEvents }).then(params => {
                //所有智能事件联动并集
                linkEvent = { ...WebApp.EventCaps, ...WebApp.DefaultEvent };
                params.name.forEach((ruleLink, index) => {
                    ruleLink.forEach((item, i) => {
                        if (item === 'default') {
                            linkEvent = WebApp.EventCaps;
                        } else {
                            linkEvent[item] = true;
                        }
                    });
                });
            }).catch(err => {
                linkEvent = WebApp.EventCaps;
            });
        }
        this.setState({
            scene,
            PresetID: 0,
            supportedRules,
            ruleTemp,
            cfgName,
            flowStaCaps: { ...flowStaCaps },
            supportedSceneWithoutPreset,
            supportSnapshot,
            linkEvent
        });
    }
    //获取配置
    getConfig = async (showTip, buff) => {
        let { $t, currentPlanID } = this.props;
        let { cfgName, cfgData } = this.state;
        let json = null;//配置
        try {
            if (WebApp.SupportIntelliScheme && WebApp.DeviceType.indexOf('SD') > -1) {
                json = await RPC.intelliScheme.getConfig(currentPlanID, cfgName);
            } else {
                json = await RPC.ConfigManager.getConfig(cfgName);
            }
            if (showTip) {
                message.success($t('com.Operateingsuccess'));
            }
        } catch (err) {
            message.error($t('com.Operateingfailure'));
        }
        if (!json) return;
        if (!buff) {
            cfgData = json.map(item => item.params.table);
        } else if (cfgData.length !== cfgName.length) {//通道能力不同可能导致所需配置项不一样，需将新增加入
            for (let i = cfgData.length; i < cfgName.length; i++) {
                cfgData.push(json[i].params.table);
            }
        }
        this.setState({
            cfgData,
            editingField: '',
        }, () => {
            this.renderElement(showTip);
        });
    }
    renderElement = (showTip) => {
        let { realPlanType, supportRuleTypeArr, notLinkageChanArrInMultiComp, IVSObjectMonitor: { slaveMode } } = this.props;
        let { cfgName, cfgData, ClassType, supportedRules, seletedRow, objectRule, PresetID, PresetName, currentChannel, presets, scene } = this.state;
        objectRule = _.get(cfgData, `[${cfgName.indexOf('VideoAnalyseRule')}][${currentChannel}]`, []).filter(item => item.Class === ClassType);
        // 当前选择行超出了规则的长度时，默认选择第一行
        if (seletedRow > objectRule.length - 1) {
            seletedRow = 0;
        }
        if (WebApp.SupportIntelliScheme && WebApp.DeviceType.indexOf('SD') > -1 && slaveMode && supportRuleTypeArr && supportRuleTypeArr[currentChannel] && supportRuleTypeArr[currentChannel][ClassType]) {//联动模式使用联动规则
            supportedRules = supportRuleTypeArr[currentChannel][ClassType];
            objectRule = objectRule.filter(i => supportedRules.includes(i.Type));//联动态下过滤下已有规则是否在可添加范围内，不是则不显示
        }
        this.setState({
            loading: true,
            presets: [],
            PresetID: 0,
            seletedRow,
            isShowPreset: false,
            objectRule: [...objectRule],
            // availableRules: (supportedRules || []).filter(r => objectRule.reduce((t, c) => (c.Type === r ? t + 1 : t), 0) < scene.SupportedRules[r].MaxRules),
            timeSection: objectRule[seletedRow] && objectRule[seletedRow].EventHandler.TimeSection
        }, () => {
            if (objectRule.length) {
                this.ObjectMonitorPlugin.current.setBtnStatus(0); // 重新设置按钮样式
                this.ObjectMonitorPlugin.current.setDrawingExcludeStatus(false);
                if (showTip) {
                    this.ObjectMonitorPlugin.current.clearStateShapeIdFunc(); // 清空保存排除区shapeID的数组
                }
                this.ObjectMonitorPlugin.current.drawRule(objectRule[seletedRow].Enable);
                if (this.configForm) {
                    this.configForm.props.form.resetFields(); //重置表单
                }
            } else {
                // 如果当前没有规则，要拉流
                this.ObjectMonitorPlugin.current.drawRule(false);
                // 清空规则，同时隐藏插件操作栏
                this.ObjectMonitorPlugin.current.clearRule();
            }
        });
    }
    // 修改时间计划表
    changeTimeSection = (value) => {
        let { timeSection } = this.state;
        timeSection = value;
        this.setState({ timeSection });
    }
    //获取“新增规则”下拉菜单
    getRuleTypeMenu = () => {
        let { $t } = this.props;
        const { supportedRules, objectRule, scene } = this.state;
        const availableRules = (supportedRules || []).filter(r => objectRule.reduce((t, c) => (c.Type === r ? t + 1 : t), 0) < scene.SupportedRules[r].MaxRules);
        return (<Menu onClick={this.addRule}>
            {availableRules.sort().map(item => <Menu.Item key={item}>{$t(translations[item])}</Menu.Item>)}
        </Menu>);
    };
    //生成表格数据
    getTableData = () => {
        const { objectRule } = this.state;
        return objectRule.map((item, index) => ({ ...item, key: item.Name + index }));
    }
    //使能规则
    enableRule = (e, record, index) => {
        const objectRule = [...this.state.objectRule];
        e.stopPropagation();
        this.setState({
            objectRule: objectRule.map(item => {
                if (item.Config === record.Config) {
                    return { ...item, Enable: !record.Enable };
                } else {
                    return { ...item };
                }
            })
        }, () => {
            if (index === this.state.seletedRow) {
                this.ObjectMonitorPlugin.current.drawRule(!record.Enable);
            }
        });
    }
    //删除规则
    deleteRule = (e, record, index) => {
        let objectRule = [...this.state.objectRule];
        // const availableRules = [...this.state.availableRules];
        let { seletedRow, editingField, cfgName, cfgData, PresetID, currentChannel, flowStaCaps, supportedRules, scene } = this.state;
        e.stopPropagation();
        objectRule = objectRule.filter((item, idx) => index !== idx);
        seletedRow = (index > seletedRow ? seletedRow : (seletedRow > 0 ? seletedRow - 1 : 0));
        //删除规则时去掉相应的流量统计
        if (PresetID === 0) {
            let tfConfig = _.get(cfgData, [cfgName.indexOf('TrafficFlowStat') + '', currentChannel + '', 'Config'], []);
            tfConfig.forEach(el => {
                if (record.Type.includes(el.StatType) && PresetID === el.PresetID) {
                    el.Enable = false;
                }
                if (el.StatType !== 'Vehicle') return;
                if (this.props.langlocale.locale !== 'zh-cn') return;
                if (_.get(this.state.scene, 'SupportedSceneParams.SupportLocalWord') === false) return;
                //删除机动车规则时，本地字清空
                // _.find(cfgData[cfgName.indexOf('VideoAnalyseGlobal')][currentChannel], { 'PtzPresetId': PresetID }).Detail.PlateHints = [];
            });
        } else if (Object.keys(flowStaCaps).length && PresetID !== withoutPresetId) {//预置点0下配置不删除，其他的需同规则一致删除
            let trafficFlow = _.get(cfgData, [cfgName.indexOf('TrafficFlowStat') + '', currentChannel + '', 'Config'], []);
            cfgData[cfgName.indexOf('TrafficFlowStat')][currentChannel].Config = trafficFlow.filter((item) => {
                return item.PresetID !== PresetID || (item.PresetID === PresetID && !record.Type.includes(item.StatType));
            });
        }
        this.setState({
            objectRule,
            // availableRules: (supportedRules || []).filter(r => objectRule.reduce((t, c) => (c.Type === r ? t + 1 : t), 0) < scene.SupportedRules[r].MaxRules),
            seletedRow,
            editingField: (index === seletedRow ? '' : editingField),
            cfgData,
            timeSection: objectRule[seletedRow] && objectRule[seletedRow].EventHandler.TimeSection
        }, () => {
            const { objectRule, seletedRow, PresetID } = this.state;
            this.ObjectMonitorPlugin.current.cover(); // 调整插件位置
            if (objectRule.length) {
                if (PresetID === withoutPresetId) {
                    this.ObjectMonitorPlugin.current.setDrawingExcludeStatus(false);
                    this.ObjectMonitorPlugin.current.drawRule(objectRule[seletedRow].Enable);
                } else {
                    this.ObjectMonitorPlugin.current.setBtnStatus(0); // 重新把图标置为选中检测区
                    this.ObjectMonitorPlugin.current.setDrawingExcludeStatus(false);
                    this.ObjectMonitorPlugin.current.drawRule(objectRule[seletedRow].Enable);
                }
            } else {
                // 清空规则，同时隐藏插件操作栏
                this.ObjectMonitorPlugin.current.clearRule();
            }
        });
    }
    //增加规则
    addRule = async ({ key }) => {
        let { availableRules, ruleTemp, objectRule, PresetID, seletedRow, flowStaCaps, cfgName, cfgData, currentChannel, supportedRules, scene } = this.state;
        if (objectRule.length !== 0) {
            let hasEmpty = await this.saveRule();
            if (hasEmpty) return;
        }
        seletedRow = objectRule.length;
        /**
         * Start DTS000784536 【结构化】在视频结构化-规则配置界面，点击默认后机动车检测规则中的车辆抠图没有恢复默认
         * 深拷贝模版ruleTemp
         */
        objectRule = [...objectRule, { ..._.cloneDeep(ruleTemp[key]), Name: this.getUniqueName() }];
        /**
         * END DTS000784536
         */
        // let { availableRules, ruleTemp, objectRule } = this.state;
        this.setState({
            // availableRules: (supportedRules || []).filter(r => objectRule.reduce((t, c) => (c.Type === r ? t + 1 : t), 0) < scene.SupportedRules[r].MaxRules),
            objectRule,
            seletedRow,
            editingField: '',
            cfgData,
            timeSection: objectRule[seletedRow] && objectRule[seletedRow].EventHandler.TimeSection
        }, () => {
            this.ObjectMonitorPlugin.current.cover(); // 调整插件位置
            this.ObjectMonitorPlugin.current.setBtnStatus(0); // 重新把图标置为选中检测区
            this.ObjectMonitorPlugin.current.setDrawingExcludeStatus(false);
            this.ObjectMonitorPlugin.current.drawRule(true);
        });
    }
    // 修改规则名称
    changeRuleName = (e) => {
        let { $t } = this.props;
        let val = e.target.value;
        let { editingField, seletedRow, objectRule } = this.state;
        // 字符串长度不符合规范
        if (lengthB(e.target.value) <= 63) {
            objectRule[seletedRow][editingField] = val;
            this.setState({
                objectRule: [...objectRule]
            });
        } else {
            return false;
        }
    }
    //修改规则名或者规则类型
    editRule = (e) => {
        let { $t } = this.props;
        let val = e.target.value;
        let { editingField, seletedRow, objectRule, currentEditRuleName } = this.state;
        if (_.isEmpty(val)) {
            objectRule[seletedRow][editingField] = currentEditRuleName;
            this.setState({
                objectRule: [...objectRule]
            });
            // 提示规则名称不能为空
            message.error($t('com.MustName'));
        }
        this.setState({
            editingField: ''
        });
    }
    //生成唯一的规则名
    getUniqueName = () => {
        const { $t } = this.props;
        let { cfgName, cfgData, objectRule, ClassType, currentChannel } = this.state;
        let defaultName = 'OM-';
        let reg = new RegExp('(^' + defaultName + ')(\\d*)$');
        const otherRules = _.get(cfgData, `[${cfgName.indexOf('VideoAnalyseRule')}][${currentChannel}]`, []).filter(item => item.Class !== ClassType);
        let numArr = [];
        let newNum = 0;
        otherRules.concat(objectRule).forEach(el => {
            let match = reg.exec(el.Name);
            if (match) {
                numArr.push(match[2] - 0);
            }
        });
        const maxNum = _.max(numArr) || 0;
        newNum = _.difference(new Array(maxNum).map((el, i) => i + 1), numArr).shift();
        return defaultName + (newNum ? newNum : maxNum + 1);
    }
    //选中行
    clickRow = async (record, index) => {
        if (this.clickingRow) {
            return false;
        }
        this.clickingRow = true;
        let { cfgName, cfgData, objectRule, ClassType, PresetID, seletedRow } = this.state;
        let hasEmpty = await this.saveRule();
        window.setTimeout(() => {
            this.clickingRow = false;
        }, 1000);
        if (hasEmpty) return;
        this.setState({
            seletedRow: index,
            timeSection: objectRule[index] && objectRule[index].EventHandler.TimeSection
        }, () => {
            this.ObjectMonitorPlugin.current.setBtnStatus(0); // 重新把图标置为选中检测区
            this.ObjectMonitorPlugin.current.setDrawingExcludeStatus(false);
            this.ObjectMonitorPlugin.current.drawRule(record.Enable);
        });
    }
    //保存当前规则数据到state里，并刷新表单
    saveRule = () => {
        const { ruleType, $t } = this.configForm.props;
        let { cfgName, cfgData, PresetID, ClassType, seletedRow, objectRule, currentChannel, scene } = this.state;
        let Scene = _.find(cfgData[cfgName.indexOf('VideoAnalyseGlobal')][currentChannel], { 'PtzPresetId': PresetID });
        if (!objectRule[seletedRow]) {
            return new Promise(res => res());
        }
        if (Array.isArray(objectRule[seletedRow].Config.SizeFilter.MaxSize)) {
            let isEmpty = objectRule[seletedRow].Config.SizeFilter.MaxSize.every(item => {
                return item === 0;
            });
            if (isEmpty) {
                message.error($t('com.PleaseDrawShapeTip'));
                return true;
            }
        }
        const vals = this.configForm.props.form.getFieldsValue();
        //特征向量提取
        if (vals.FeatureExtractEnable !== undefined) {
            objectRule[seletedRow].Config.FeatureExtractEnable = vals.FeatureExtractEnable;
        }
        if (vals.HumanBodyFeatureExtractEnable !== undefined) {
            objectRule[seletedRow].Config.HumanBodyFeatureExtractEnable = vals.HumanBodyFeatureExtractEnable;
        }
        //特征向量加密
        if (vals.Encryption !== undefined) {
            objectRule.forEach(_rule => {
                if (_rule.Type === 'VehicleDetect') return;
                _rule.Config.EncryptFeatureVector = vals.Encryption;
                if (_rule.Type === 'NonMotorDetect') {
                    _rule.Config.EncryptHumanBodyFeatureVector = vals.Encryption;
                }
            });
        }
        //目标类型
        if (vals.ObjectTypes !== undefined) {
            objectRule[seletedRow].Config.ObjTypes = vals.ObjectTypes;
        }
        // 最短持续时间
        if (vals.MinDuration !== undefined) {
            objectRule[seletedRow].Config.MinDuration = vals.MinDuration;
        }
        // 事件联动
        objectRule[seletedRow].EventHandler = this.EventHandler.submit();
        // 时间计划表
        objectRule[seletedRow].EventHandler.TimeSection = this.state.timeSection;
        if (plugin.isInStalled) {
            //保存画面中的规则配置PS:由于插件中的Config参数用的是引用，所以上面的修改会改动submit中提交的值
            objectRule[seletedRow].Config.DetectLine = this.ObjectMonitorPlugin.current.submit().rule.Config.DetectLine;
            objectRule[seletedRow].Config.SizeFilter = this.ObjectMonitorPlugin.current.submit().rule.Config.SizeFilter;
            objectRule[seletedRow].Config.DetectRegion = this.ObjectMonitorPlugin.current.submit().rule.Config.DetectRegion; // 检测区数据
        }
        return new Promise((resolve) => {
            this.setState({
                cfgData,
                objectRule
            }, () => {
                this.configForm.props.form.resetFields();
                resolve();
            });
        });
    }
    //刷新页面
    refresh = () => {
        this.ScheduleDialog.current && this.ScheduleDialog.current.refreshSchedule();
        this.getConfig(true);
    }
    confirm = async (e) => {
        let { cfgName, cfgData, PresetID, ClassType, seletedRow, objectRule, scene, currentChannel } = this.state;
        let { currentPlanID, SupportConfigingShow } = this.props;
        // 数据是否符合保存标准
        let result = true;
        if (objectRule.length !== 0) {
            let hasEmpty = await this.saveRule();
            if (hasEmpty) return;
        }
        const { $t, nextStep } = this.props;
        cfgData[cfgName.indexOf('VideoAnalyseRule')][currentChannel] = this._reBackConfig();
        let configNameArr = [...cfgName];
        let configJsonArr = [...cfgData];
        //保存时判断名称是否有重复
        if (this.areRuleNamesDuplicate(this._reBackConfig())) {
            return false;
        }
        this.ObjectMonitorPlugin.current.saveShapeStatus();
        // 判断是否规则是否已经添加检测区
        cfgData[cfgName.indexOf('VideoAnalyseRule') + ''][currentChannel].forEach((item, i) => {
            if (item.Class === ClassType && item.PtzPresetId === PresetID) {
                if (Math.max.apply(null, (item.Config.DetectRegion || []).toString().split(',')) <= 0) {
                    message.error($t('com.PleaseDrawShapeTip'));
                    result = false; // 不允许保存
                }
            }
        });
        if (result && e) {
            RPC.ConfigManager.setConfig(configNameArr, configJsonArr).then(() => {
                nextStep('IVSObjectMonitor');
                message.success($t('com.Operateingsuccess'));
            }).catch(err => {
                message.error($t('com.Operateingfailure'));
            });
        }
    }
    //生成配置
    _reBackConfig = () => {
        const { $t } = this.props;
        let { cfgName, cfgData, PresetID, ClassType, seletedRow, objectRule, scene, currentChannel } = this.state;
        let otherRules = cfgData[cfgName.indexOf('VideoAnalyseRule')][currentChannel].filter(v => {
            return v.Class !== ClassType || (v.Class === ClassType && v.PtzPresetId !== PresetID);
        });
        return otherRules.concat(_.cloneDeep(objectRule));
    }
    //判断修改的规则名是否已经存在
    areRuleNamesDuplicate = (rules) => {
        const { $t } = this.props;
        const { ClassType } = this.state;
        //人群密度图协议比较奇怪，只有一个规则，Name分布在这个规则下面每个区域里，这里把它们提取出来
        let cdm = rules.filter(rl => rl.Type === 'CrowdDistriMap')[0];
        if (cdm && cdm.Config.PeopleStatRegions) {
            rules = rules.concat(cdm.Config.PeopleStatRegions.map(i => ({
                ...cdm,
                Name: i.Name
            })));
        }
        return rules.some((el1, index1) => {
            return rules.some((el2, index2) => {
                //不判断与我无关的模块的命名冲突
                if (el1.Class !== ClassType && el2.Class !== ClassType) return false;
                //不和自己比
                if (index1 === index2) return false;
                // 预置点不一样
                if (el1.PtzPresetId !== el2.PtzPresetId) return false;
                //规则名不一样
                if (el1.Name !== el2.Name) return false;
                let tempStr = '';
                let str1 = this.ruleType2Str(el1.Type);
                let str2 = this.ruleType2Str(el2.Type);
                if (index1 > index2) {
                    tempStr = str1;
                    str1 = str2;
                    str2 = tempStr;
                }
                message.error(('' + $t(str1) + ' ' + $t('com.And') + ' ' + $t(str2) + ' ' + $t('com.GroupNameSameTip')));
                return true;
            });
        });
    }
    ruleType2Str = (str) => {
        switch (str) {
        case 'CrossLineDetection':
            str = 'appEventCrossLineDetection';
            break;
        case 'CrossRegionDetection':
            str = 'appEventCrossRegionDetection';
            break;
        case 'StereoNumberStat':
            str = 'NumberStat';
            break;
        case 'StereoFallDetection':
            str = 'StereoFallDetection';
            break;
        case 'StereoFightDetection':
            str = 'StereoFightDetection';
            break;
        case 'StereoStayDetection':
            str = 'StereoManStayDetection';
            break;
        case 'StereoManNumDetection':
            str = 'StereoAbnormalCountDetection';
            break;
        case 'StereoDistanceDetection':
            str = 'StereoApproachDetection';
            break;
        case 'HumanTrait':
            str = 'ivs.PeopleDetect';
            break;
        case 'NonMotorDetect':
            str = 'ivs.NonMotorAnalyse';
            break;
        case 'VehicleDetect':
            str = 'ivs.VehicleAnalyse';
            break;
        case 'TrafficParkingSpaceNoParking':
        case 'TrafficParkingSpaceParking':
            str = 'ivs.CarposDete';
            break;
        case 'ObjectPlacement':
            str = 'ivs.ObjectPlacement';
            break;
        case 'ObjectRemoval':
            str = 'ivs.ObjectRemoval';
            break;
        default:
            break;
        }
        //物品遗留和物品消失，使用与协议对照的翻译。
        return str;
    }
    default = async () => {
        const { $t, currentPlanID, IVSObjectMonitor: { slaveMode }, supportRuleTypeArr } = this.props;
        this.isClickDefault = true;
        let { cfgName, cfgData, PresetID, ClassType, seletedRow, objectRule, supportedRules, currentChannel, scene } = this.state;
        let res = null, VideoAnalyseGlobalConfig = null;
        if (WebApp.SupportIntelliScheme && WebApp.DeviceType.indexOf('SD') > -1 && slaveMode && supportRuleTypeArr && supportRuleTypeArr[currentChannel] && supportRuleTypeArr[currentChannel][ClassType]) {//联动模式使用联动规则
            supportedRules = supportRuleTypeArr[currentChannel][ClassType];
        }
        try {
            if (WebApp.SupportIntelliScheme && WebApp.DeviceType.indexOf('SD') > -1) {
                res = await RPC.intelliScheme.getDefault(currentPlanID, cfgName);
                VideoAnalyseGlobalConfig = await RPC.intelliScheme.getConfig(currentPlanID, 'VideoAnalyseGlobal');
            } else {
                res = await RPC.ConfigManager.getDefault(cfgName);
                VideoAnalyseGlobalConfig = await RPC.ConfigManager.getConfig('VideoAnalyseGlobal');
            }
            message.success($t('com.OperateingSuccessTip'));
        } catch (err) {
            this.isClickDefault = false;
            message.error($t('com.Operateingfailure'));
        }
        if (!res) return;
        // console.log(res);
        cfgName.forEach((val, index) => {
            let config = res[index].params.table;
            let otherRules = null;
            switch (val) {
            case 'TrafficFlowStat':
                break;
            case 'VideoAnalyseRule': //只恢复 物体分类相关智能
                objectRule = config[currentChannel].filter(function (rule) {
                    return rule.Class === ClassType && rule.PtzPresetId === PresetID;
                });
                otherRules = cfgData[index][currentChannel].filter(item => item.Class !== ClassType || (item.Class === ClassType && item.PtzPresetId !== PresetID));
                cfgData[index][currentChannel] = otherRules.concat(_.cloneDeep(objectRule));
                break;
            case 'VideoAnalyseGlobal':
                break;
            default:
                break;
            }
        });
        this.setState({
            cfgData,
            objectRule,
            // availableRules: (supportedRules || []).filter(r => objectRule.reduce((t, c) => (c.Type === r ? t + 1 : t), 0) < scene.SupportedRules[r].MaxRules),
            seletedRow: 0,
            editingField: ''
        }, () => {
            const { objectRule, seletedRow } = this.state;
            this.ObjectMonitorPlugin.current.cover(); // 调整插件位置
            if (objectRule.length) {
                this.ObjectMonitorPlugin.current.setBtnStatus(0); // 重新把图标置为选中检测区
                this.ObjectMonitorPlugin.current.setDrawingExcludeStatus(false);
                this.ObjectMonitorPlugin.current.clearStateShapeIdFunc(); // 清空保存排除区shapeID的数组
                this.ObjectMonitorPlugin.current.drawRule(objectRule[seletedRow].Enable);
            } else {
                // 清空规则，同时隐藏插件操作栏
                this.ObjectMonitorPlugin.current.clearRule();
            }
        });
    }
    // 获取module配置
    // getModuleConfig = () => {
    //     let { cfgData, cfgName, ClassType, PresetID, currentChannel } = this.state;
    //     let moduleConfig = {};
    //     // console.log(cfgData[cfgName.indexOf('VideoAnalyseModule') + '']);
    //     if (cfgData[cfgName.indexOf('VideoAnalyseModule') + ''] && cfgData[cfgName.indexOf('VideoAnalyseModule') + ''][currentChannel]) { // 判空处理，会出现有智能方案，但是不能选择预置点的情况
    //         cfgData[cfgName.indexOf('VideoAnalyseModule') + ''][currentChannel].forEach(item => {
    //             if (item && item.Type === ClassType && item.PtzPresetId === PresetID) {
    //                 moduleConfig = item;
    //             }
    //         });
    //     }
    //     return moduleConfig;
    // }
    hidePlugin = () => {
        this.ObjectMonitorPlugin.current.hide();
    }
    showPlugin = () => {
        this.ObjectMonitorPlugin.current.cover();
    }
    // 关闭插件,供tabpanel切换时使用
    closePlugin = () => {
        let { currentChannel } = this.state;
        this.ObjectMonitorPlugin.current.hide();
        this.ObjectMonitorPlugin.current.close(currentChannel);
        // 清空规则，防止在规则配置中重绘
        this.ObjectMonitorPlugin.current.clearRule();
    }
    // 重新打卡插件
    reOpenPlugin = () => {
        let { currentChannel, objectRule } = this.state;
        setTimeout(() => {
            this.ObjectMonitorPlugin.current.cover();
            this.ObjectMonitorPlugin.current.open(1, currentChannel, 0);
        }, 500);
        // 重新监听
        this.ObjectMonitorPlugin.current.addPluginEvent();
        this.ObjectMonitorPlugin.current.drawRule(objectRule[0] && objectRule[0].Enable || false);
    }
    render () {
        let { $t, langlocale, publicState, isShowNext, currentPlanID, notLinkageChanArrInMultiComp, masterSlaveGroupObj } = this.props;
        let { availableRules, objectRule, seletedRow, flowStaCaps, cfgName, cfgData, curRlue, PresetID, PresetName, scene, presets, ClassType,
            linkEvent, trackEnable, currentChannel, channelOptions, isShowPreset, loading, supportSnapshot } = this.state;
        const multiple = {
            type: 'normal',
            multiple: 1
        };
        const englishColWidth = [{ span: 9, xxl: 6 }, { span: 9, xxl: 9 }, { span: 5, xxl: 5, offset: 0 }];
        let layOutColWidth = (langlocale.locale === 'zh-cn') ? publicState.getGrid(multiple).colWidth : englishColWidth;
        return (
            <div
                className='_IVSObjectMonitorRuleConfig_'
            >
                {(WebApp.CHANNEL_NUMBER > 1) || WebApp.DeviceType.includes('SD') ? (
                    <React.Fragment>
                        <div className='topContent'>
                            <Row>
                                {(WebApp.CHANNEL_NUMBER > 1) ?
                                    <Col span={10}>
                                        <LabelSelect
                                            options={channelOptions} value={currentChannel} label={$t('com.Channel')}
                                            controlWidth={[5, 12]}
                                            onDropdownVisibleChange={this.onTogChnLst}
                                            onChange={this.changeChannel} />
                                    </Col> : null
                                }
                            </Row>
                        </div>
                        <div style={{ borderBottom: '1px solid #e8e8e8' }}></div>
                    </React.Fragment>
                ) : null}
                <div className="content">
                    <Row className='list-item' style={{ marginBottom: 16 }}>
                        <Col span={10}>
                            <Dropdown
                                overlay={this.getRuleTypeMenu()}
                                placement="bottomLeft"
                                trigger={['click']}
                                disabled={!objectRule || objectRule.length >= scene.MaxRules}
                                getPopupContainer={() => {
                                    return document.querySelector('._IVSObjectMonitorRuleConfig_');
                                }}
                            >
                                <Button type='primary'>{$t('ivs.AddRule')}</Button>
                            </Dropdown>
                        </Col>
                        {/* <a style={{ float: 'right' }}onClick={this.showPictureOverlay}>{$t('com.PicInfo')}</a> */}
                    </Row>
                    <Row className='list-item' style={{ marginBottom: 4 }}>
                        <Table
                            dataSource={this.getTableData()}
                            pagination={false}
                            onRow={(record, index) => ({
                                onClick: this.clickRow.bind(this, record, index)
                            })}
                            rowClassName={(record, index) => {
                                return index === seletedRow ? 'selectedRow' : 'editable-row'; // 给选中的行加上背景色
                            }}
                            style={{
                                minHeight: 190, maxHeight: 292, overflowY: 'auto', marginTop: 10, marginBottom: 20,
                                borderLeft: '1px solid #e8e8e8', borderRight: '1px solid #e8e8e8', borderBottom: '1px solid #e8e8e8'
                            }}
                        >
                            <Column title={$t('com.Numbers')} dataIndex="key" key="number" width={100} align='center' render={(text, record, index) => index + 1} />
                            <Column
                                title={$t('com.Name')}
                                dataIndex="Name"
                                key="ruleName"
                                width={200}
                                align='center'
                                render={(text, record, index) => {
                                    let { editingField, seletedRow } = this.state;
                                    if (editingField === 'Name' && index === seletedRow) {
                                        return <Input
                                            value={text}
                                            style={{ width: 160, lineHeight: '20px', height: 20 }}
                                            ref={(e) => { if (e) { e.focus(); } }}
                                            onBlur={this.editRule}
                                            onChange={this.changeRuleName}
                                            onClick={e => { e.stopPropagation(); }}
                                        />;
                                    } else {
                                        return <span
                                            title={text}
                                            className='editable-cell-value-wrap'
                                            onClick={() => { this.setState({ editingField: 'Name', currentEditRuleName: text }); }}
                                            style={{ width: 160, height: 18, lineHeight: '18px' }}
                                        >{text}</span>;
                                    }
                                }}
                            />
                            <Column
                                title={$t('com.Type')}
                                dataIndex="Type"
                                key="ruleType"
                                width={200}
                                align='center'
                                render={text => $t(translations[text])}
                            />
                            <Column
                                title={$t('com.On')}
                                dataIndex="Enable"
                                key="enable"
                                width={100}
                                align='center'
                                render={(text, record, index) => {
                                    return <Switch checked={record.Enable} onClick={(checked, e) => this.enableRule(e, record, index)} />;
                                }}
                            />
                            <Column
                                title={$t('com.Delete')}
                                dataIndex="none"
                                key="delete"
                                width={100}
                                align='center'
                                render={(text, record, index) => {
                                    return <Icon type='delete' onClick={(e) => this.deleteRule(e, record, index)} />;
                                }}
                            />
                        </Table>
                    </Row>
                    <Row>
                        <Col span={12} style={{ width: 470 }}>
                            <ObjectMonitorPlugin
                                ref={this.ObjectMonitorPlugin}
                                // moduleConfig={this.getModuleConfig()}
                                ruleConfig={objectRule[seletedRow]}
                                currentPtzId={PresetID}
                                currentPtzName={PresetName}
                                withoutPresetId={withoutPresetId}
                                currentChannel={currentChannel}
                                tabKey={this.props.tabKey}
                                isShowPreset={isShowPreset}
                                masterSlaveGroupObj={masterSlaveGroupObj}
                                {...this.props}
                                isShowZoom={this.state.isShowZoom}
                                isShowFocus={this.state.isShowFocus}
                                isShowIris={this.state.isShowIris}
                                cfgName={cfgName}
                                cfgData={cfgData}
                                objectRule={objectRule}
                            />
                        </Col>
                        <Col span={12}>
                            <div className="config-wrap">
                                {loading && objectRule[seletedRow] ? (
                                    <React.Fragment>
                                        <ConfigForm
                                            scene={scene}
                                            ruleType={objectRule[seletedRow].Type}
                                            currentChannel={currentChannel}
                                            flowStaCaps={flowStaCaps}
                                            cfgName={cfgName}
                                            cfgData={cfgData}
                                            PresetID={PresetID}
                                            withoutPresetId={withoutPresetId}
                                            objectRule={objectRule}
                                            seletedRow={seletedRow}
                                            wrappedComponentRef={(form) => this.configForm = form}
                                            history={this.props.history}
                                            showCrossLine={this.ObjectMonitorPlugin.current && this.ObjectMonitorPlugin.current.showCrossline}
                                            hideCrossline={this.ObjectMonitorPlugin.current && this.ObjectMonitorPlugin.current.hideCrossline}
                                            drawCrossLineDetection={this.ObjectMonitorPlugin.current && this.ObjectMonitorPlugin.current.drawCrossLineDetection}
                                            saveRule={this.saveRule}
                                            reRenderPlugin={() => {
                                                let { objectRule, seletedRow } = this.state;
                                                if (this.ObjectMonitorPlugin.current) {
                                                    if (PresetID === withoutPresetId) {
                                                        this.ObjectMonitorPlugin.current.drawRule(objectRule[seletedRow].Enable);
                                                    } else {
                                                        this.ObjectMonitorPlugin.current.drawRule(objectRule[seletedRow].Enable);
                                                        this.ObjectMonitorPlugin.current.setBtnStatus(0); // 重新设置按钮样式
                                                    }
                                                    this.ObjectMonitorPlugin.current.setDrawingExcludeStatus(false);
                                                }
                                            }}
                                            hidePlugin={this.hidePlugin}
                                            showPlugin={this.showPlugin}
                                            publicState={publicState}
                                            trackEnable={trackEnable}
                                            supportSnapshot={supportSnapshot}
                                        />
                                        {this.state.timeSection && this.state.timeSection.length !== 0 ?
                                            <ScheduleDialog
                                                ability={Ability}
                                                ref={this.ScheduleDialog}
                                                colWidth={layOutColWidth}
                                                Time={this.state.timeSection}
                                                onClose={this.ObjectMonitorPlugin.current && this.ObjectMonitorPlugin.current.cover}
                                                onOpen={this.ObjectMonitorPlugin.current && this.ObjectMonitorPlugin.current.hide}
                                                onChange={this.changeTimeSection}
                                            /> : null
                                        }
                                        {objectRule[seletedRow] ? <EventHandler
                                            customCaps={linkEvent} // 控制联动事件是否显示
                                            caps={objectRule[seletedRow].EventHandler}
                                            onlyShowOneChannel={currentChannel}
                                            ref={(EventHandler) => this.EventHandler = EventHandler}
                                            colWidth={layOutColWidth}
                                        /> : null}
                                    </React.Fragment>
                                ) : null}
                                <div className="operation fn-width200">
                                    {(!(WebApp.DeviceType.indexOf('SD') > -1) || !WebApp.SpecialNavigation) && <Button onClick={this.preStep} className='fn-marr10' disabled={this.state.disabled}>{$t('com.Previous')}</Button>}
                                    <Button onClick={this.confirm} type='primary' className='fn-marr10 _submitButton' disabled={this.state.disabled}>{isShowNext ? $t('com.NextStep') : $t('com.Apply')}</Button>
                                    <Button onClick={this.refresh} className='fn-marr10 _refreshButton' disabled={this.state.disabled}>{$t('com.Refresh')}</Button>
                                    <Button onClick={this.default} className="_defaultButton" disabled={this.state.disabled}>{$t('com.Comdefault')}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default LangConsumer(bindLifecycle(RuleConfig));